$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
.playlists {
  margin: 16px;
}

header {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  margin: 16px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;

  * {
    margin: 0;
  }

  p {
    color: rgba(55, 55, 55, 0.5);

    @media (prefers-color-scheme: dark) {
      color: rgba(193, 193, 193, 0.5);
    }
  }
}

.playlist {
  width: 100%;

  .link {
    margin-left: auto;
  }

  .background {
    display: flex;
    margin: 8px 0;
    background-color: rgba(55, 55, 55, 0.1);
    padding: 16px;
    border-radius: 8px;

    align-items: center;
    justify-content: start;
    flex-direction: row;

    @media (prefers-color-scheme: dark) {
      background-color: rgba(193, 193, 193, 0.1);
    }

    .title {
      font-size: 1.25rem;
      font-weight: 600;
      margin: 0;
    }

    .description {
      font-size: 1rem;
      margin: 0;
    }

    .options {
      margin-left: 8px;
      display: flex;

      justify-content: center;
      align-items: center;
      gap: 12px;

      * {
        width: 20px;
        height: 20px;

        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 600;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        img {
          object-fit: contain;
        }
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr)); /* Adjust the minmax value as needed */

    overflow: auto;
    overflow-x: scroll;
    white-space: nowrap;
    gap: 16px;

    max-width: 100%;
  }
}

.song {
  display: flex;
  margin: 8px 0;
  min-width: 170px;

  overflow: hidden;

  flex-direction: column;

  .cover-art {
    width: 100%;
    height: 170px;
    border-radius: 8px;
    margin-bottom: 8px;
  }

  h1 {
    font-size: 1rem;
    margin: 0;
    font-weight: 600;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  p {
    color: rgba(55, 55, 55, 0.5);
    margin: 0;
    font-weight: 400;
    font-size: 0.85rem;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media (prefers-color-scheme: dark) {
      color: rgba(247, 247, 247, 0.5);
    }
  }

  .buttons {
    margin-top: 8px;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    gap: 16px;

    button {
      font-family: $font-family;
      background-color: transparent;
      border: none;
      color: $tint-color;
      font-size: 1rem;
      cursor: pointer;
      padding: 0;
      font-weight: 600;

      display: flex;
      align-items: center;
      gap: 8px;

      img {
        width: 20px;
        height: 20px;
      }

      @include breakpoint(medium) {
        font-size: 0.75rem;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
